import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <main className="flex items-center justify-center h-screen bg-[#121212] py-7">
        <div className="flex items-center flex-col justify-center w-full gap-64 py-2  mx-4">

          <div className="text-center">
            <div className="w-16 h-16 block m-auto mb-3">
            </div>
            <div className="mb-2 text-4xl font-normal bg-gradient-to-r from-[#fff] via-[#adadad] to-[#5b5b5b] inline-block text-transparent bg-clip-text leading-tight">
              Coming Soon<span className="text-orange-400 font-bold">.</span>
            </div>
            <p className="font-light text-[#5d5d5d] mb-4">Sarthak Labde Official Website</p>
            <div className="flex gap-2 justify-center">
              <a href="#" className="w-12 h-12 flex items-center justify-center bg-[#1b1b1b] rounded-full">
                <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 24 24" fill="none" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-twitter"><path d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z" /></svg>
              </a>
              <a href="#" className="w-12 h-12 flex items-center justify-center bg-[#1b1b1b] rounded-full">
                <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 24 24" fill="none" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-linkedin"><path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z" /><rect x="2" y="9" width="4" height="12" /><circle cx="4" cy="4" r="2" /></svg>
              </a>
              <a href="#" className="w-12 h-12 flex items-center justify-center bg-[#1b1b1b] rounded-full">
                <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 24 24" fill="none" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-instagram"><rect x="2" y="2" width="20" height="20" rx="5" ry="5" /><path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z" /><line x1="17.5" y1="6.5" x2="17.51" y2="6.5" /></svg>
              </a>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default App;
